import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SecurityService from "../services/SecurityService";

Vue.use(VueRouter)

const routes = [
  {
    path: '/', name: 'Home',
    component: Home
  }, {
    path: '/sss', name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/login',  name: 'Login',
    component: () => import('../views/Login.vue')
  }, {
    path: '/users', name: 'Users',
    component: () => import('../views/UsersList.vue')
  }, {
    path: '/user/:id', name: 'User',
    component: () => import('../views/UsersSingle.vue')
  }, {
    path: '/incomebets', name: 'IncomeBetsList',
    component: () => import('../views/IncomeBetsList.vue')
  }, {
    path: '/incomebets/:id', name: 'IncomeBetsSingle',
    component: () => import('../views/IncomeBetsSingle.vue')
  }, {
    path: '/orderbets', name: 'OrderBetsList',
    component: () => import('../views/OrderBetsList.vue')
  }, {
    path: '/orderbets/group', name: 'OrderBetsListGroup',
    component: () => import('../views/OrderBetsListGroup.vue')
  }, {
    path: '/orderbets/group/:orderBetBaseId', name: 'OrderBetsListGroupBet',
    component: () => import('../views/OrderBetsList.vue')
  }, {
    path: '/orderbets/:id', name: 'OrderBetsSingle',
    component: () => import('../views/OrderBetsSingle.vue')
  }, {
    path: '/purses-logs', name: 'PursesLogsList',
    component: () => import('../views/PursesLogsList.vue')
  }, {
    path: '/business', name: 'IncomeProfit',
    component: () => import('../views/IncomeProfit.vue')
  }, {
    path: '/withdrawals', name: 'PurseWithdrawalPage',
    component: () => import('../views/PurseWithdrawalPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path !== "/login" && SecurityService.getAccessToken() == null) {
    next("/login");
    return;
  }

  next();
})

export default router
